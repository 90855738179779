import { Modal as MuiModal } from '@mui/material';
import Image from 'next/image';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ModalTheme } from '@/components/UI/constants';
import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled(MuiModal)`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: fixed;
	width: 100vw;
`;
// content
const Content = styled.div<{ theme: ModalTheme; bgTheme: ModalTheme }>`
	// 移动端不显示
	display: none;
	@media ${devices.laptop} {
		background-image: ${props =>
			props.bgTheme === ModalTheme.Default
				? 'unset'
				: 'linear-gradient(to bottom, #fff6e6 12%, #fff 74%)'};
		${props => props.theme === ModalTheme.Primary && boxElevation(8, color.primaryYellow)}
		align-items: center;
		background-color: ${color.whiteColor};
		border: solid 1px ${color.blackColor};
		border-radius: 1px;
		justify-content: center;
		max-height: 90vh;
		max-width: 90vw;
		position: relative;
		&:focus {
			outline: none;
		}
		display: flex;
		overflow: unset;
	}
`;
const ContentHeader = styled.div`
	align-items: center;
	display: flex;
	height: 20px;
	justify-content: end;
	max-width: 100vw;
	position: absolute;
	right: -42px;
	top: -42px;
	width: calc(100% + 42px * 2);
`;
const ContentHeaderClose = styled.div`
	align-items: center;
	background-color: ${color.whiteColor};
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 12px;
	position: absolute;
	right: 0;
	top: 0;
`;
const ContentMain = styled.div`
	width: 100%;
`;
const ContentMainHeader = styled.div`
	align-items: center;
	background-color: ${color.primaryYellow};
	border-bottom: solid 1px #000;
	display: flex;
	gap: 14px;
	height: 38px;
	padding-left: 32px;
	padding-right: 32px;
`;
const ContentMainHeaderRadioOne = styled.div`
	background-color: #ffd3d3;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const ContentMainHeaderRadioTwo = styled.div`
	background-color: #fdecc6;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const ContentMainHeaderRadioThree = styled.div`
	background-color: #eafec6;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const ContentMainContent = styled.div`
	max-height: 80vh;
	overflow-y: auto;
	width: 100%;
`;

// mobileContent
const MobileContent = styled.div<{ mobileTheme: ModalTheme }>`
	background-color: #fff;
	background-image: ${props =>
		props.mobileTheme === ModalTheme.Default
			? 'unset'
			: 'linear-gradient(to bottom, #fff6e6 12%, #fff 74%)'};
	display: block;
	height: 100vh;
	width: 100vw;
	@media ${devices.laptop} {
		display: none;
	}
`;
const MobileContentHeader = styled.div`
	align-items: center;
	display: flex;
	height: 58px;
	justify-content: space-between;
	padding: 0 16px;
`;
const MobileContentHeaderInfo = styled.div`
	align-items: center;
	display: flex;
`;
const HeaderInfoSplit = styled.div`
	background-color: #000;
	height: 14px;
	margin: 0 16px;
	width: 1px;
`;
const HeaderInfoTitle = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
`;
const MobileContentMain = styled.div`
	height: calc(100vh - 58px);
	overflow-y: scroll;
	padding-bottom: 140px;
	@media ${devices.laptop} {
		padding-bottom: 0;
	}
`;

export interface ModalTriggerProps {
	/** 点击相应 */
	onClick: () => void;
}
export interface ModalContentProps {
	/** 取消显示 */
	onCancel: () => void;
}

const Modal = ({
	open,
	onCancel,
	maskClosable = false,
	theme = ModalTheme.Default,
	bgTheme = ModalTheme.Default,
	triggerRender,
	contentRender,
	mobileContentRender,
	mobileTheme = ModalTheme.Default,
	title
}: {
	/**
	 * 由外部控制显示
	 */
	open?: boolean;
	/** 点击遮罩层或右上角叉或取消按钮的回调 */
	onCancel?: () => void;
	/** 点击蒙层是否允许关闭 */
	maskClosable?: boolean;
	/**
	 * 弹框主题 （仅pc端）
	 * @param ModalTheme
	 * 默认 default 简洁边框
	 */
	theme?: ModalTheme;
	/** 背景主题 */
	bgTheme?: ModalTheme;
	/** 触发器render */
	triggerRender?: (props: ModalTriggerProps) => ReactNode;
	/** 内容render */
	contentRender: (props: ModalContentProps) => ReactElement;
	// /** 是否是移动端 */
	// mobile: boolean;
	/** ？移动端内容render，有的话优先展示 */
	mobileContentRender?: ReactNode;
	/** 移动端主题，主要影响移动端背景色 */
	mobileTheme?: ModalTheme;
	/** 用于移动端展示 */
	title?: string;
}) => {
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		if (open !== undefined) {
			setVisible(!!open);
		}
	}, [open]);

	const handleCancel = () => {
		if (triggerRender) {
			setVisible(false);
		}
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<>
			{triggerRender && triggerRender({ onClick: () => setVisible(true) })}
			<Container
				open={visible}
				onClose={() => {
					if (maskClosable) handleCancel();
				}}
			>
				<>
					<Content bgTheme={bgTheme}>
						<ContentHeader>
							<ContentHeaderClose onClick={() => handleCancel()}>
								<Image
									src="/icon/close.svg"
									alt="close"
									width={16}
									height={16}
									unoptimized
									loader={imageLoader}
								/>
							</ContentHeaderClose>
						</ContentHeader>
						<ContentMain>
							{theme === ModalTheme.Primary && (
								<ContentMainHeader>
									<ContentMainHeaderRadioOne />
									<ContentMainHeaderRadioTwo />
									<ContentMainHeaderRadioThree />
								</ContentMainHeader>
							)}
							<ContentMainContent>
								{contentRender({ onCancel: () => handleCancel() })}
							</ContentMainContent>
						</ContentMain>
					</Content>
					<MobileContent mobileTheme={mobileTheme}>
						<MobileContentHeader>
							<MobileContentHeaderInfo>
								<Image
									src="/icon/logo-transparent.svg"
									alt="logo"
									unoptimized
									loader={imageLoader}
									width={24}
									height={24}
								/>
								{title && <HeaderInfoSplit />}
								{title && <HeaderInfoTitle>{title}</HeaderInfoTitle>}
							</MobileContentHeaderInfo>
							<Image
								onClick={() => handleCancel()}
								src="/icon/close.svg"
								alt="close"
								width={14}
								height={14}
								unoptimized
								loader={imageLoader}
								style={{
									margin: '6px'
								}}
							/>
						</MobileContentHeader>
						<MobileContentMain>
							{mobileContentRender ||
								contentRender({ onCancel: () => handleCancel() })}
						</MobileContentMain>
					</MobileContent>
				</>
			</Container>
		</>
	);
};

export default Modal;
